﻿// Define Variables before importing the boostrap.scss file
/** Open Sans */
@import url(https://use.typekit.net/gsb6gzt.css);

$font-family-sans-serif:      "calluna-sans","Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;

$font-family-calluna:         "calluna","Helvetica Neue", Arial, serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$font-size-base:              1.125rem !default; // 18px

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;

// primary and secondary site colors
$primary: #285283;
$secondary: #101C2A;
$light-blue: #7AC3DB;
$copy: #061B1E;
$black: #000;
$white: #fff;
$light-gray:#E1E1E1;

$body-bg:                   $white !default;
$body-color:                $copy !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           0 !default;
$btn-border-radius-lg:        0 !default;
$btn-border-radius-sm:        0 !default;

$input-border-radius:         0 !default;
$input-border-radius-lg:      0 !default;
$input-border-radius-sm:      0 !default;
$input-border-color:          $secondary !default;

// Import boostrap css
@import "../bootstrap/scss/bootstrap";

html, body {
    margin:0;
    padding:0;
}

select.form-control:not([size]):not([multiple]) {
    height: calc(2.625rem);
}

.font-16 {
    font-size:16px;
}

.text-white {
    color:$white;
}

.text-black {
    color:$black;
}

.text-light-blue {
    color:$light-blue;
}

.text-copy {
    color:$copy;
}

.semi-bold {
    font-weight:600;
}

small {
    font-weight:300;
}

// Custom Css Here
h1, h2, h3, h4, h5 {
    color:$primary;
}

hr {
    border-top:1px solid $light-gray;
}

// background, border, hover-background, hover-border, active-background, active-border
.btn-white-border {
    @include button-variant(transparent, $white, $white, $white, $secondary, $white);
    border:2px solid;
    color: $white;
    font-weight:600;
    font-size:16px;
    text-transform:uppercase;
    letter-spacing:3px;
    padding: 15px 24px;

    &.btn-sm {
        font-size:14px;
        letter-spacing:2px;
        padding: 10px 20px;
    }
}

.btn-blue-border {
    @include button-variant(transparent, #08252A, #101C2A, #101C2A, $secondary, $secondary);
    border:2px solid;
    color: #08252A;
    font-weight:600;
    font-size:16px;
    text-transform:uppercase;
    letter-spacing:3px;
    padding: 15px 24px;

    &.btn-sm {
        font-size:14px;
        letter-spacing:2px;
        padding: 10px 20px;
    }
}

.navbar {
    border-top:8px solid $light-gray;
    border-bottom:8px solid $light-gray;
    padding: 2.5rem 1rem;

    height:170px;

    @include media-breakpoint-down(lg) {
        height:150px;
    }

    @include media-breakpoint-down(md) {
        height:auto;
        padding: 1.5rem 1.75rem;
    }

    &.navbar-light {
        background-color:$white;
        
        .navbar-nav {

            @include media-breakpoint-down(md) {
                padding-top: 36px;
            }

            .nav-item {
                border:2px solid transparent;

                &:last-of-type {
                    margin-right:0px !important;
                }

                &.active {
                    border:2px solid $secondary;

                    a.nav-link {
                        color:$primary;
                    }

                    @include hover-focus {
                        color:$white;
                        background-color:$secondary;
                        border:2px solid $secondary;
    
                        a.nav-link {
                            color:$white;
                        }
                    }
                }

                @include hover-focus {
                    color:$white;
                    background-color:$secondary;

                    a.nav-link {
                        color:$white;
                    }
                }

                &.show {
                    color:$white;
                    background-color:$secondary;

                    a.nav-link {
                        color:$white;
                    }
                }
            }

            .nav-link {
                font-family:$font-family-sans-serif;
                font-weight:600;
                font-size:18px;
                text-transform:uppercase;
                color:$copy;
                letter-spacing:2px;
                padding-right:1rem !important;
                padding-left:1rem !important;

                /*@include hover-focus {
                    color:$white;
                    background-color:$secondary;
                    border-color:$secondary;
                }

                &.active {
                    color:$primary;
                }*/

                &::after {
                    display:none;

                    @include media-breakpoint-down(md) {
                        display:inline-block;
                    }
                }

                @include media-breakpoint-down(lg) {
                    padding-left: .5rem !important;
                    padding-right: .5rem !important;
                    font-size:16px;
                }

                @include media-breakpoint-down(md) {
                }
            }
        }

        .navbar-toggler {
            .navbar-toggler-icon {
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
            }
            color:$copy;
            border: 2px solid transparent;
            border-color: $copy;
        }

        .dropdown-menu {
            border-radius:0;
            border: none;
            background-clip:border-box;
            background-color:$primary;
            padding: 0 0;
            top:97%;
            left:-2px;

            @include media-breakpoint-down(md) {
                background-color:$white;
            }

            .dropdown-item {
                color:$white;

                @include media-breakpoint-down(md) {
                    color:$copy;
                }

                &.active {
                    background-color:$secondary;
                    color:$white;
                }

                @include hover-focus {
                    background-color:$secondary;
                    color:$white;

                    &.active {
                        background-color:$secondary;
                        color:$white;
                    }
                } 
            }
        }
    }

    .navbar-brand {

        img {
            width:90%;

            @include media-breakpoint-down(sm) {
                width:200px;
            }
        }
    }

    .current-menu-parent {

        &.nav-item {
            border:2px solid $secondary !important;
            color:$primary;

            @include hover-focus {
                color:$white;
                background-color:$secondary;
                border-color:$secondary;

                a.nav-link {
                    color:$white;
                }
            }

            a.nav-link {
                color:$primary;
            }
        }
    }
}

main {

    .section-container {

        .page-section {
            overflow:hidden;

            .section-caption {
                h1 {
                    font-family:$font-family-calluna;
                    color:$white;
                    font-size:65px;
                    letter-spacing:2px;
                    line-height: 65px;
                    margin-bottom:2rem;
                }

                div, p {
                    color:$white;
                    font-size:16px;
                    font-weight: 400;
                }

                @include media-breakpoint-down(lg) {
                    h1 {
                        font-family:$font-family-calluna;
                        color:$white;
                        font-size:45px;
                        letter-spacing:2px;
                        line-height: 45px;
                        margin-bottom:1.5rem;
                    }
    
                    div, p {
                        color:$white;
                        font-size:16px;
                        font-weight: 400;
                    }
                }

                @include media-breakpoint-down(sm) {
                    opacity:0.9;

                    h1 {
                        font-family:$font-family-calluna;
                        color:$white;
                        font-size:25px;
                        letter-spacing:2px;
                        line-height: 25px;
                        margin-bottom:1.15rem;
                    }
    
                    div, p {
                        color:$white;
                        font-size:14px;
                        font-weight: 400;
                    }
                }
            }

            .section-arrow-container {
                position:absolute;
                bottom:0px;
                left:0px;
                width:100%;

                .section-arrow {
                    cursor:pointer;
                }
            }
        }

        #page-section-1 {
            background-image:url('../images/home-banner-planning.png');
            background-repeat:no-repeat;
            background-position: center right;
            background-size:cover;
        }
        
        #page-section-2 {
            background-image:url('../images/home-banner-bldg.png');
            background-repeat:no-repeat;
            background-position: center left;
            background-size:cover;
            height:100vh;
        }
    }


    .page-header {
        padding:20px;
        background-color:$secondary;
        height:150px;
        text-align:center;

        h1 {
            color:$white;
            font-size:30px;
            font-weight:600;
            letter-spacing: 3px;
            text-transform: uppercase;
            margin-bottom:0px;
        }

        h2 {
            color:$light-blue;
            font-size:22px;
            font-weight:600;
            letter-spacing: 3px;
            text-transform: uppercase;

            a {
                color:$light-blue;
                text-decoration:none;
            }
        }
    }
}



.section-caption-mobile {
    h1 {
        font-family:$font-family-calluna;
        color:$white;
        font-size:65px;
        letter-spacing:2px;
        line-height: 65px;
        margin-bottom:2rem;
    }

    div, p {
        color:$white;
        font-size:16px;
        font-weight: 400;
    }

    @include media-breakpoint-down(lg) {
        h1 {
            font-family:$font-family-calluna;
            color:$white;
            font-size:45px;
            letter-spacing:2px;
            line-height: 45px;
            margin-bottom:1.5rem;
        }

        div, p {
            color:$white;
            font-size:16px;
            font-weight: 400;
        }
    }
}

.content-block {
    padding-top:80px;
    padding-bottom:80px;

    &.bottom-border {
        border-bottom:30px solid $light-gray;
    }

    &.top-border {
        border-top:30px solid $light-gray;
    }

    &.teaser {
        padding-bottom:40px;
    }

    &.experience {
        padding-bottom:20px;
    }
}

.teaser-heading {
    font-size:30px;
    font-weight:600;
    text-transform:uppercase;
    letter-spacing: 3px;
    line-height: 30px;
    margin-bottom: 4rem;
}

.teaser {
    .title {
        font-size:30px;
        font-weight:600;
        line-height: 30px;
        margin-bottom: 12px;
    }

    .description {
        line-height: 30px;
        margin-bottom: 45px;
    }
}

.why-different {
    h4 {
        font-size:30px;
        line-height: 45px;
        font-weight:600;
        letter-spacing: 3px;
    }
}

.project-list {
    a {
        color:$copy;

        @include hover-focus {
            text-decoration: none;
            color:$primary;
        } 
    }

    .title {
        font-size:22px;
        font-weight:600;
        line-height: 22px;
        margin-bottom: 5px;
    }
}

.experience-info {
    .line-item {
        font-size:24px;
        margin-bottom:5px;

        span {
            font-weight:600;
        }
    }

    .description {
        font-size:16px;
        line-height:32px;
    }
}

.flickity-slider {
    display: flex;
    align-items: center;
}

.logo-slider {
    background-color:transparent;

    .flickity-viewport {
        min-height:180px;
    }

    &.is-hidden {
        display:none;
    }

    .carousel-cell {
        width: 25%;
        vertical-align: middle;
        padding:20px;

        @include media-breakpoint-down(md) {
            width: 33.33%;
        }

        @include media-breakpoint-down(sm) {
            width: 50%;
        }

        @include media-breakpoint-down(xs) {
            width: 60%;
        }
    }

    .carousel-cell-image {
        display: block;
        max-width: 60%;
        height: auto;
        margin: 0 auto;

        @include media-breakpoint-down(sm) {
            max-width: 100%;
        }
    }

    .flickity-prev-next-button {
        width: 100px;
        height: 100%;
        border-radius: 0;
        opacity:1;
    }

    .flickity-button:disabled {
        opacity: 1;
        cursor: auto;
        pointer-events:auto;
    }

    .flickity-prev-next-button .flickity-button-icon {
        position: absolute;
        left: 25%;
        top: 25%;
        width: 50%;
        height: 50%;
    }

    .flickity-prev-next-button:hover {
    }

    /* arrow color */
    .flickity-prev-next-button .arrow {
        fill: black;
        opacity:1;
    }
    .flickity-prev-next-button.no-svg {
        color: black;
        opacity:1;
    }
    /* arrow position */
    .flickity-prev-next-button.previous {
        background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
        background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
        background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 );
        left: 0px;
    }
    .flickity-prev-next-button.next {
        background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 );        
        right: 0px;
    }

    .flickity-prev-next-button[disabled] {
        display: none;
    }
}

.footer {
    .parent-link {

        @include media-breakpoint-down(lg) {
            margin-bottom:15px;
        }

        a {
            text-transform: uppercase;
            font-size:20px;
            font-weight:600;
            color:$secondary;
            letter-spacing: 3px;

            @include media-breakpoint-down(lg) {
                font-size:14px;
            }

            @include media-breakpoint-down(sm) {
                font-size:14px;
            }
        }
    }

    .sub-menu {
        margin-top:15px;
        margin-bottom:0px;

        @include media-breakpoint-down(md) {
            margin-top:0px;
            margin-bottom:20px;
        }

        div {
            margin-top:12px;

            @include media-breakpoint-down(lg) {
                line-height: 14px;
            }

            @include media-breakpoint-down(sm) {
                line-height: 14px;
            }
        
            a {
                color:$secondary;
                font-size:18px;
                font-weight:600;
                line-height: 18px;

                @include media-breakpoint-down(lg) {
                    font-size:14px;
                    line-height: 14px;
                }

                @include media-breakpoint-down(sm) {
                    font-size:14px;
                    line-height: 14px;
                }
            }
        }
    }

    .footer-contact-info {
        .copy {
            font-size:14px;
            font-weight:400;
            line-height:20px;
        }
    }
}

.modal {
    padding-right:0px !important;

    .modal-dialog {
        min-width:90%;

        .modal-content {
            min-height: 70vh;
            height:100%;
            background-color:transparent;
            border:none;

            #gallery-carousel {
                min-height: 100%;
                height:100%;

                .carousel-inner {
                    min-height: 70vh;
                    height:70vh;

                    .carousel-item {
                        min-height:100%;
                        height:100%;

                        img {
                            height:100%;
                            position: absolute;   
                            top: 50%;
                            left:0;
                            right:0;
                            transform: translateY(-50%);
                            margin:auto;
                            object-fit:contain;

                            &.horizontal {
                                width:100%;
                                height:100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .close {
        position:absolute;
        top:40px;
        right:40px;
        z-index:1000;

        span {
            padding: 20px;
        }

        @include media-breakpoint-down(md) {
            top:20px;
            right:10x;
        }         
    }
}

.modal-backdrop {
    opacity: 0.85;

    &.show {
        opacity: 0.85;
    }
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-duration: .6s;
    transition-property: opacity;
}
   
.carousel-fade  .carousel-item.active,
.carousel-fade  .carousel-item-next.carousel-item-left,
.carousel-fade  .carousel-item-prev.carousel-item-right {
    opacity: 1;
}
   
.carousel-fade .active.carousel-item-left,
.carousel-fade  .active.carousel-item-right {
    opacity: 0;
}
   
.carousel-fade  .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade  .active.carousel-item-prev {
    transform: translateX(0);
    transform: translate3d(0, 0, 0);
}

.carousel-control-next, .carousel-control-prev {
    z-index:100;
}

.carousel-control-next-icon {
    background-image:  url('../images/arrow-right.png');
    width: 27px;
    height: 49px;
}

.carousel-control-prev-icon {
    background-image:  url('../images/arrow-left.png');
    width: 27px;
    height: 49px;
}

.nf-form-wrap {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 100% !important;
}
  
// Styling all field containers
.nf-field-container {
    @include media-breakpoint-down(xs) {
        width: 100% !important;
        float: none !important;
        margin-right: -15px !important;
        margin-left: -15px !important;
        padding: 0 15px !important;
        margin-bottom: 1rem !important;
    }
}
  
// Styling all field wraps/
.field-wrap {
    min-width: 100% !important;
}
  
  //Styling all field labels
.field-wrap label {
    font-weight: 400 !important;
}
  
.nf-form-fields-required {
    padding-bottom: 20px;
}